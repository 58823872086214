<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest
                                        ?'(测试环境,数据定期删除)':''}}当前：科目余额表</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :sm="12" :md="4">
                                <vxe-toolbar class="pull-left" style=" height: 42px; " custom export ref="xToolbar2">
                                </vxe-toolbar>
                                <el-form-item label="会计期间:">
                                    <el-date-picker
                                            v-model="pickerDateMonth"
                                            style=" padding-top: 2px; width: 80px"
                                            type="month"
                                            align="right"
                                            size="mini"
                                            @change="$_pickerMonthEvent(),getAccountBalanceReport()"
                                            unlink-panels
                                            :clearable="false"
                                            range-separator="至"
                                            start-placeholder="开始月份"
                                            end-placeholder="结束月份">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="4" :md="3">
                                <el-form-item label="科目级别:">
                                    <el-input-number style="width: 80px;" size="mini" v-model="formData.level" clearable
                                                     @change="getAccountBalanceReport()"
                                                     controls-position="right" :min="1" :max="10"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="5">
                                <el-form-item label="科目:">
                                    <el-select
                                            ref="roleSubject" v-model="formData.roleSubject"
                                            style="width: 100%"
                                            size="mini"
                                            clearable
                                            multiple
                                            collapse-tags
                                            remote
                                            :remote-method="getRoleSubjectByAccountId"
                                            filterable
                                            default-first-option
                                            placeholder="可选多选">
                                        <el-option
                                                v-for="item in roleSubjectList"
                                                :key="item.id_subject"
                                                :label="item.id_subject +'-'+item.subject_name"
                                                :value="item.subject_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="5">
                                <el-checkbox style="margin-left:2px;margin-top: 12px;" v-model="formData.noHappendMoney"
                                             @change="getAccountBalanceReport()">无发生额不显示
                                </el-checkbox>
                                <el-checkbox style="margin-top: 12px;" v-model="formData.noMoney"
                                             @change="getAccountBalanceReport()">无余额不显示
                                </el-checkbox>
                            </el-col>
                            <el-col :sm="12" :md="7">
                                <el-button style=" margin-top: 8px; " type="danger" round
                                           :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="$_openType({path: '/allVoucher',
                                query: {subject_id : $refs.xGrid.getCurrentRecord().subject_id,startDate: formatDate(pickerDate[0],'yyyy-MM-dd'),
                                endDate: formatDate(pickerDate[1],'yyyy-MM-dd')}})" size="mini">明细
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="getAccountBalanceReport" size="mini">搜索
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="buildAccountBalanceReport" size="mini">生成
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="buildPZ" size="mini">结转损溢
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="unBuildPZ" size="mini">反结转损溢
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-col>

                </el-row>
                <!--:tree-config="{line: true}"-->
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                show-footer
                                keep-source
                                highlight-current-row
                                ref="xGrid"
                                id="xGridAccountBalanceReport"
                                :row-key="true"
                                row-id="id"
                                :export-config="{type: ['xlsx'] ,types: ['xlsx', 'csv', 'html', 'xml', 'txt'],isFooter:true,isColgroup:true}"
                                align="right"
                                :custom-config="{storage:true}"
                                :height="(this.getViewHeight() - 130)+''"
                                size="mini"
                                :footer-method="footerMethod"
                                :tree-config="{expandAll: true,line: true,children: 'children',reserve: true}"
                                :cell-class-name="cellClassName"
                                :row-class-name="$_tableRowClassNameSuccess"
                                :data="tableDataMain"
                                :columns="tableColumn">
                            <template #operate="{row}">
                                <vxe-button icon="el-icon-view" title="明细" circle
                                            @click="openVoucher(row)"></vxe-button>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>

            </el-form>
        </el-main>

    </div>

</template>

<script>
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            // 本月第一天
            var startDate = new Date(year + '-' + month + '-01 00:00:00');
            return {
                //自定义表头
                /*                headerRowStyle:{maxHeight:'80px',height:'80px'},
                                headerCellStyle:{maxHeight:'80px',height:'80px'},
                                showHeaderOverflow:false,*/
                type: '',//空代表默认，CN代表出纳用的（外购单一览）
                formData: {//搜索筛选
                    noHappendMoney: false,
                    noMoney: false,
                    level: 4,
                    name: null,
                    roleSubject: [],
                },
                tableColumn: [
                    {
                        field: 'subject_id', width: 160, title: '科目代码',treeNode:true, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/accountBalanceReport/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field: 'subject_name', width: 290, title: '科目名称',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/accountBalanceReport/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        title: '期初余额',
                        children: [
                            {
                                field: 'borrow_money_begin', width: 120, title: '借方', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/accountBalanceReport/searchHeard', this.pickerDate)
                                }
                            }, {
                                field: 'lent_money_begin',
                                width: 120,
                                title: '贷方',
                                formatter: ({cellValue}) => {
                                    return cellValue
                                },
                                slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/accountBalanceReport/searchHeard', this.pickerDate)
                                }
                            },
                        ]
                    },
                    {
                        title: '本期发生额',
                        children: [
                            {
                                field: 'borrow_money_now', width: 120, title: '借方', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/accountBalanceReport/searchHeard', this.pickerDate)
                                }
                            }, {
                                field: 'lent_money_now',
                                width: 120,
                                title: '贷方',
                                formatter: ({cellValue}) => {
                                    return cellValue
                                },
                                slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/accountBalanceReport/searchHeard', this.pickerDate)
                                }
                            },
                        ]
                    },
                    {
                        title: '本年累计发生额',
                        children: [
                            {
                                field: 'borrow_money_year_total', width: 120, title: '借方', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/accountBalanceReport/searchHeard', this.pickerDate)
                                }
                            }, {
                                field: 'lent_money_year_total',
                                width: 120,
                                title: '贷方',
                                formatter: ({cellValue}) => {
                                    return cellValue
                                },
                                slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/accountBalanceReport/searchHeard', this.pickerDate)
                                }
                            },
                        ]
                    },
                    {
                        title: '期末余额',
                        children: [
                            {
                                field: 'borrow_money_end', width: 120, title: '借方', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/accountBalanceReport/searchHeard', this.pickerDate)
                                }
                            }, {
                                field: 'lent_money_end',
                                width: 120,
                                title: '贷方',
                                formatter: ({cellValue}) => {
                                    return cellValue
                                },
                                slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/accountBalanceReport/searchHeard', this.pickerDate)
                                }
                            },
                        ]
                    },
                ],
                tableDataMain: [],
                filterData: {},
                sortData: {"type_id": "asc"},
                pickerDate: [startDate, this.$getMonthLast(startDate)],
                pickerDateMonth: startDate,
                activeName: 'first',
                roleSubjectList: [],//授权科目，可以多选
            }
        }, computed: {},
        methods: {
            //获取不同用户的授权科目权限
            getRoleSubjectByAccountId(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/roleSubject/getRoleSubjectByAccountId',
                    data: {
                        query: query
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.roleSubjectList = response.data;
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            openVoucher(row) {
                const {href} = this.$router.resolve({path: '/voucher', query: {id: row.id}})
                window.open(href, '_blank');
            },
            findCDWBListByBillId() {
                this.$axios({
                    method: 'post',
                    url: '/admin/allVoucher/findCDWBListByBillId',
                    params: {
                        bill_id: this.showModel.billId
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataModel = response.data.list;
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            cellClassName({row, column}) {
                if (column.property === 'cn_state_man') {
                    if (row.cn_state_man) {
                        return 'col-success'
                    } else {
                        return 'col-fail'
                    }
                } else if (column.property === 'cash_type_id_list') {
                    return 'col-ableClick'
                }
            },
            unBuildPZ(){
                //结转损益
                this.$XModal.confirm('您确定反结转损溢：' + this.$_formatDate(this.pickerDate[0], 'yyyy-MM')).then(type => {
                    if (type === 'confirm') {
                        const loading = this.$loading({
                            lock: true,
                            text: '饮茶先啦，数据处理中，请耐心等待!',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        let searchUrl = '/admin/accountBalanceReport/unBuildPZ'
                        this.$axios({
                            method: 'POST',
                            url: searchUrl,
                            data: {
                                pickerDate: this.pickerDate,
                            }
                        }).then((response) => {         //这里使用了ES6的语法
                            loading.close();
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '生成成功',
                                    type: 'success'
                                });
                                this.getAccountBalanceReport();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) => {
                            loading.close();
                            console.log(error)
                        });
                    }
                })
            },
            buildPZ(){
                //结转损益
                this.$XModal.confirm('您确定结转损溢：' + this.$_formatDate(this.pickerDate[0], 'yyyy-MM')).then(type => {
                    if (type === 'confirm') {
                        const loading = this.$loading({
                            lock: true,
                            text: '饮茶先啦，数据处理中，请耐心等待!',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        let searchUrl = '/admin/accountBalanceReport/buildPZ'
                        this.$axios({
                            method: 'POST',
                            url: searchUrl,
                            data: {
                                pickerDate: this.pickerDate,
                            }
                        }).then((response) => {         //这里使用了ES6的语法
                            loading.close();
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '生成成功',
                                    type: 'success'
                                });
                                this.getAccountBalanceReport();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) => {
                            loading.close();
                            console.log(error)
                        });
                    }
                })
            },
            buildAccountBalanceReport() {
                this.$XModal.confirm('您确定重新生成：' + this.$_formatDate(this.pickerDate[0], 'yyyy-MM') + ' ~ ' + this.$_formatDate(new Date(), 'yyyy-MM') + ' 的所有数据?').then(type => {
                    if (type === 'confirm') {
                        const loading = this.$loading({
                            lock: true,
                            text: '饮茶先啦，数据处理中，请耐心等待!',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        let searchUrl = '/admin/accountBalanceReport/buildAccountBalanceReport'
                        this.$axios({
                            method: 'POST',
                            url: searchUrl,
                            data: {
                                pickerDate: this.pickerDate,
                            }
                        }).then((response) => {         //这里使用了ES6的语法
                            loading.close();
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '生成成功',
                                    type: 'success'
                                });
                                this.getAccountBalanceReport();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) => {
                            loading.close();
                            console.log(error)
                        });
                    }
                })
            },
            getAccountBalanceReport() {
                //this.tableDataMain = []
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let searchUrl = '/admin/accountBalanceReport/search'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                        formData: this.formData
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page
                        this.$refs.xGrid.setAllTreeExpand(true)
                    }
                }).catch((error) => {
                    loading.close();
                    console.log(error)
                });
            },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                    } else {
                        if (
                            column.property === 'borrow_money_begin' || column.property === 'lent_money_begin' ||
                            column.property === 'borrow_money_now' || column.property === 'lent_money_now' ||
                            column.property === 'borrow_money_year_total' || column.property === 'lent_money_year_total' ||
                            column.property === 'borrow_money_end' || column.property === 'lent_money_end'
                        ) {
                            sums.push(this.$_sumNum(data, column.property))
                        } else {
                            sums.push('')
                        }
                    }
                })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
            },
        },
        created() {
            this.$nextTick(() => {
                this.getRoleSubjectByAccountId();
                if (!this.isBlankVue(this.$route.query.type)) {
                    this.type = this.$route.query.type;
                }
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)

            })
        }
    };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
    }

    .el-form-item__content .el-input {
        width: 130px;
    }

    /deep/ .el-input--suffix .el-input__inner {
        padding-right: initial !important;
    }

    /deep/ input[popperclass="myAutocomplete"] {
        padding: 3px !important;
    }

    /deep/ .el-input__suffix {
        right: 0px !important;
    }
</style>
